@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Poppins", sans-serif;
}
.bg-desktop {
  background-image: url('./assets/masjid.png');
  background-size: cover;
  background-position: center;
}

/* Background for mobile */
@media (max-width: 767px) {
  .bg-mobile {
    background-image: url('./assets/masjid_mobile.png'); 
    background-size: cover;
    background-position: center;
  }
}

:root {
  --primary-color: #04535A;
  --secondary-color: #F3B175;
  --hitam-color: #1E1E1E;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Jumlah baris maksimum */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
