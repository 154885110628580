.swiper-button-next,
.swiper-button-prev {
          display: none;
        }
.swiper-pagination {
  margin-top: 100px !important;
}
.swiper-pagination-bullet {
  margin-top: 100px !important;
  width: 40px !important;
  border-radius: 0.375rem !important;
  background-color: #cccccc !important;
  /* border: 1px solid #000000 !important; */
}

.swiper-pagination-bullet-active {
  margin-top: 100px !important;
  width: 40px !important;
  border-radius: 0.375rem !important;
  background-color: #04535A !important; 
  /* border: 1px solid #000000 !important; */
}